<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="8">
        <v-text-field
          v-model="contract.name"
          label="Loja"
          disabled
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="contract.cnpj"
          label="CNPJ"
          disabled
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="contract.contracts"
      sort-by="calories"
      class="elevation-1"
    >
      <template v-slot:no-results> Nenhum produto encontrado </template>
      <template v-slot:no-data> Nenhum produto encontrado </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Produtos e serviços</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="1000px" persistent>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                Adicionar
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Novo produto</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="7">
                      <v-select
                        :items="products"
                        v-model="product.idProduct"
                        label="Produto/Serviço"
                        item-text="description"
                        item-value="id"
                        hide-details
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="5">
                      <v-text-field
                        label="Controle"
                        v-model="product.control"
                        dense
                        maxlength="10"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="product.idProduct == 1">
                    <v-col cols="12" md="3">
                      <v-checkbox
                        v-model="product.license.price"
                        label="Preço"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="3">  
                      <v-checkbox
                        v-model="product.license.inventory"
                        label="Inventario"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="3">  
                    <v-checkbox
                      v-model="product.license.inactivate"
                      label="Inativação"
                    ></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="3">  
                    <v-checkbox
                      v-model="product.license.plate"
                      label="Placas"
                    ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancelar
                </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Salvar </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="edit(item)"> mdi-pencil </v-icon>
        <v-icon small class="mr-2" @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <v-row class="mt-4" justify="end">
      <v-col md="2" class="text-right">
        <v-btn color="secondary" to="/clientes">VOLTAR</v-btn>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="3000" :color="colorSnack" outlined>
      {{ text }}
    </v-snackbar>
    <v-dialog
      v-model="dialogDelete"
      transition="dialog-top-transition"
      max-width="500"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5"
          >Deseja realmente excluir o produto<br />
          {{ itemDelete.product.description }}?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="error" @click="closeDelete">Cancelar</v-btn>
          <v-btn depressed color="primary" @click="remove">Confirmar</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from "axios";
import { baseApiUrl } from "@/global";
export default {
  name: "ContractClient",
  data: () => ({
    dialog: false,
    idClient: null,
    editing: false,
    contract: {},
    text: null,
    colorSnack: "",
    snackbar: false,
    dialogDelete: false,
    itemDelete: { product: {} },
    editedItem: {},
    products: [{ name: "Selecione um produto/serviço", id: null }],
    product: {
      idProduct: null,
      control: "",
      license: {
        price: true,
        inventory: true,
      }
    },
    headers: [
      { text: "Produto", value: "product.description", sortable: false },
      { text: "Controle", value: "control", sortable: false },
      { text: "Ações", value: "actions", sortable: false },
    ],
  }),
  methods: {
    close() {
      this.dialog = false;
      this.editing = false;
      this.product.idProduct = null;
      this.product.control = "";
    },
    save() {
      if (this.product.idProduct !== null) {
        const url = `${baseApiUrl}/contract`;
        const method = this.editing ? "put" : "post";
        this.product.idClient = parseInt(this.idClient);
        if(this.product.idProduct != 1){
          this.product.license = null
        }
        const data = this.editing
          ? {
              oldItem: this.editedItem,
              newItem: this.product,
            }
          : this.product;
        axios[method](url, data)
          .then(() => {
            this.getContract();
            this.close();
            this.openSnack("Produto/Serviço salvo com sucesso", "success");
            this.editing = false;
          })
          .catch((error) => {
            if (error.response) {
              this.openSnack(error.response.data, "error");
            }
          });
          this.product.license = {
            price: true,
            inventory: true,
          }
      } else {
        this.openSnack("Informe um produto", "warning");
      }
    },
    edit(item) {
      console.log(item)
      this.product = {
        idProduct: item.idProduct,
        control: item.control,
        license: item.product.license
      };
      this.editedItem = Object.assign({}, item);
      this.editing = true;
      this.dialog = true;
    },
    remove() {
      axios
        .delete(`${baseApiUrl}/contract/`, { params: this.itemDelete })
        .then(() => {
          this.closeDelete();
          this.getContract();
          this.openSnack("Produto/Serviço removido com sucesso", "success");
        })
        .catch(() =>
          this.openSnack("Não foi possivel remover produto", "error")
        );
    },
    deleteItem(item) {
      this.itemDelete = item;
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    getProducts() {
      const url = `${baseApiUrl}/products`;
      axios.get(url).then((res) => {
        this.products = res.data;
      });
    },
    getContract() {
      const url = `${baseApiUrl}/contract/`;
      axios
        .get(url, {
          params: {
            idClient: this.$route.params.id,
          },
        })
        .then((res) => {
          this.contract = res.data;
        });
    },
    openSnack(text, color) {
      this.text = text;
      this.colorSnack = color;
      this.snackbar = true;
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.idClient = this.$route.params.id;
      this.getContract();
    }
    this.getProducts();
  },
};
</script>

<style>
</style>